import {
    Link as Anchor,
    Box,
    Container,
    Flex,
    GridItem,
    Heading,
    SimpleGrid,
    Text,
} from "@chakra-ui/react";
import Image from "@components/Image";
import RichTextComponent from "@components/RichText.component";
import { MarketDataType } from "@context/AppContext/App.model";
import { useTranslations } from "next-intl";
import NextLink from "next/link";
import CertificateDisplay from "@components/CertificateDisplay.component";


function DynamicFooter({
    FooterMenu,
    CertificatesView,
}: MarketDataType) {
    const { Columns, Logo, CVR } = FooterMenu;
    const dict = useTranslations("CheckoutShared");
    return (
        <Box bgColor="brand.white">
            <Box height="auto" width="64" mx="auto">
                <Image
                    src={Logo.Url}
                    alt=""
                    aspectRatio="1:1"
                    objectFit="contain"
                    size="medium"
                    sizes="256px"
                />
            </Box>
            <Box bgColor="brand.gray.300">
                <Container
                    maxW="container.xl"
                    paddingTop={10}
                    paddingBottom={10}
                    paddingX={{ base: 4, lg: 20 }}
                    bgColor="brand.gray.300"
                >
                    <SimpleGrid columns={{ base: 4, md: 6, lg: 12 }} spacing={{ base: 6, lg: 8 }}>
                        {Columns.map((column, columnIdx) => {
                            if (!column.Links.length) return;
                            return (
                                <GridItem
                                    colSpan={{ base: 2, lg: 3 }}
                                    key={`${columnIdx} - ${column.Title}`}
                                >
                                    <Heading variant="heading3" mb={4} inlineSize={"min-content"} minW="100%">
                                        {column.Title ? column.Title : dict("PaymentOptions.HeadingPaymentOptions")}
                                    </Heading>
                                    <Flex wrap="wrap" rowGap={2} columnGap={3}>
                                        {column.Links.map((link, index) => (
                                            link.Image && link.Url ?
                                                <NextLink
                                                    href={link.Url}
                                                    key={`${columnIdx}.${index} - ${column.Title}.${link.Title}`}
                                                    passHref>
                                                    <Anchor target={link.Target}>
                                                        <Box height="auto" width="7">
                                                            <Image src={link?.Image?.Url}
                                                                alt=""
                                                                aspectRatio="1:1"
                                                                objectFit="contain"
                                                                size="small"
                                                                sizes="64px" />
                                                        </Box>
                                                    </Anchor>
                                                </NextLink>
                                                :
                                                link.Image ?
                                                    <Box height="9" width="16">
                                                        <Image src={link.Image.Url}
                                                            alt=""
                                                            aspectRatio="16:9"
                                                            objectFit="contain"
                                                            size="small"
                                                            sizes="128px"
                                                        />
                                                    </Box>
                                                    : link.Url && link.Title ?
                                                        <NextLink
                                                            href={link.Url}
                                                            key={`${columnIdx}.${index} - ${column.Title}.${link.Title}`}
                                                            passHref>
                                                            <Anchor flexBasis="100%" target={link.Target}>
                                                                <Text>{link.Title}</Text>
                                                            </Anchor>
                                                        </NextLink>
                                                        :
                                                        <Text flexBasis="100%">{link.Title}</Text>
                                        ))}
                                    </Flex>
                                </GridItem>
                            );
                        })}
                    </SimpleGrid>
                </Container>
            </Box >
            <Container
                maxW="container.xl"
                paddingTop={6}
                paddingBottom={8}
                paddingX={{ base: 4, lg: 20 }}
            >
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    textAlign="center"
                    fontSize="14px !important"
                >
                    <RichTextComponent text={CVR} fontSize={"sm"} />
                </Flex>
                <CertificateDisplay boxWidth="24px" {...CertificatesView} />
            </Container>
        </Box >
    );
}
export default DynamicFooter;
