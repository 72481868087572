import {
    Box, Flex, Link as Anchor,
} from "@chakra-ui/react";
import Image from "@components/Image";
import NextLink from "next/link";

interface Props {
    Image: CMSImageType;
    Description: string;
    Link: CMSLinkType;
    boxWidth: string;
}

const CertificateDisplay = (props: Props) => {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            position="relative"
            fontSize={"sm"}>
            <Box height="auto" width={props.boxWidth} mr={2}>
                {props.Link ?
                    <NextLink href={props?.Link?.Url} passHref>
                        <Anchor target={"_blank"}>
                            <Image
                                src={props.Image?.Url}
                                alt=""
                                aspectRatio="1:1"
                                objectFit="contain"
                                size="small"
                                sizes="10vw"
                            />
                        </Anchor>
                    </NextLink>
                    :
                    <Image
                        src={props.Image?.Url}
                        alt=""
                        aspectRatio="1:1"
                        objectFit="contain"
                        size="small"
                        sizes="10vw"
                    />
                }
            </Box>
            {props.Description}
        </Flex>
    );
}

export default CertificateDisplay;


