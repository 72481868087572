import { ReactComponent as Logo } from "@assets/svg/logo.svg";
import {
  Link as Anchor,
  Box,
  Container,
  GridItem,
  Heading,
  Icon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { MarketDataType } from "@context/AppContext/App.model";
import NextLink from "next/link";
import ContactInfo from "./ContactInfo.component";
import CertificateDisplay from "@components/CertificateDisplay.component";

function Footer({
  FooterMenu,
  Contact,
  CertificatesView,
}: MarketDataType) {
  const { Columns } = FooterMenu;
  return (
    <Box bgColor="brand.white">
      <Container
        maxW="container.xl"
        paddingTop={10}
        paddingBottom={12}
        paddingX={{ base: 4, lg: 20 }}
      >
        <SimpleGrid columns={{ base: 4, md: 6, lg: 12 }} spacing={8}>
          {Columns.map((column, columnIdx) => {
            if (!column.Links.length) return;
            return (
              <GridItem
                colSpan={{ base: 2, lg: 3 }}
                key={`${columnIdx} - ${column.Title}`}
              >
                <Heading variant="heading3" mb={4}>
                  {column.Title}
                </Heading>
                {column.Links.filter((link) => link.Url).map((link, index) => (
                  <NextLink
                    href={link.Url}
                    target={link.Target}
                    key={`${columnIdx}.${index} - ${column.Title}.${link.Title}`}
                    passHref
                  >
                    <Anchor>
                      <Text lineHeight={7}>{link.Title}</Text>
                    </Anchor>
                  </NextLink>
                ))}
              </GridItem>
            );
          })}
          <GridItem colSpan={{ base: 2, lg: 3 }}>
            <ContactInfo {...Contact} />
          </GridItem>
          <GridItem
            colStart={{ base: "auto", sm: 2, md: 3, lg: 10 }}
            colSpan={{ base: 4, sm: 2, lg: 3 }}
          >
            <Icon as={Logo} width="100%" height="auto" />
          </GridItem>
        </SimpleGrid>
        <CertificateDisplay boxWidth="34px" {...CertificatesView} />
      </Container>
    </Box>
  );
}
export default Footer;
